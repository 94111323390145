<template>
  <div class=" full-width ">
    <div class="d-flex align-center mb-4">
      <span class="mr-2 title">Is form valide</span>
      <v-icon
        small
        v-if="!f1"
        color="error"
      >{{$icon('i.Block')}}</v-icon>
      <v-icon
        v-else
        color="green"
      >{{$icon('i.Checked')}}</v-icon>

      <v-checkbox
        class="ml-4"
        v-model="_dense"
        label="dense"
      />
      <v-checkbox
        class="ml-4"
        v-model="_clearable"
        label="clearable"
      />
      <v-checkbox
        class="ml-4"
        v-model="_readonly"
        label="readonly"
      />
      <v-checkbox
        class="ml-4"
        v-model="_disabled"
        label="disabled"
      />
    </div>
    <v-form v-model="f1">
      <div class="header pa-4">
        <grid
          item-min-width="300px"
          class="align-center "
        >
          <v-text-field
            placeholder="placeholder"
            :dense="_dense"
            :disabled="_disabled"
            :rules="required"
            :label="$t('t.Values')"
            type="text"
            :clearable="_clearable"
            v-model="searchInput"
            :readonly="_readonly"
          />
          <!-- <typed-doc-picker2
            :close-icon="$icon('i.Close')"
            :selected.sync="selected"
            :multiple="false"
            :queryOptions="_typedDocPickerQueryOptions"
            label="aaa"
            placeholder="placeholder"
            :dense="_dense"
            :disabled="_disabled"
            :clearable="_clearable"
            :readonly="_readonly"
            :rules="required"
          />
          <picker2
            label="aaa"
            placeholder="placeholder"
            :dense="_dense"
            :disabled="_disabled"
            :document-types="'columns'"
            :clearable="_clearable"
            :readonly="_readonly"
            :rules="required"
            :search-input.sync="searchInput"
            :selected-id.sync="selectedId"
          /> -->

          <!-- <picker
            :rules="required"
            :clearable="true"
            document-types="work-item-types"
            :attach="false"
            :label="$t('t.ActivityType')"
            :selected-id.sync="dataWorkItemTypeId"
            :query-options="queryOptionsWorkItemType"
          /> -->
          <v-btn @click="addItem1">
            Add 1
          </v-btn>
        </grid>
      </div>
      <div class="content">
        {{items}}

        <div
          v-for="(item, i) in items"
          :key="i"
          class="pa-2"
        >
          <translations
            :label="$t('t.Name')"
            :translations.sync="item.name"
            :is-new="true"
            :rules="nameRules"
            class="mr-4"
          />
          <div class="ml-6">
            <v-btn
              @click="addItem2(i)"
              class="mt-4"
            >
              Add 2
            </v-btn>
            {{item.items}}
            <div
              v-for="(item, j) in items[i].items"
              :key="j"
            >
              <translations
                :label="$t('t.Name')"
                :translations.sync="item.name"
                :is-new="true"
                :rules="nameRules"
                class="mr-4"
              />
            </div>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>

export default {
  components: {
    // Picker: () => import('@/components/picker'),
    // Picker2: () => import('@/components/picker2'),
    // TypedDocPicker2: () => import('@/components/typed-doc-picker2'),
    Grid: () => import('@/components/grid'),
    Translations: () => import('@/components/translations')

  },
  computed: {
    nameRules () {
      const MIN = 1
      const MAX = 100
      return [
        v => !!v.length || this.$t('t.IsRequired'),
        v => (v.length >= MIN && v.length <= MAX) || this.$t('t.Length')
      ]
    },
    _dense: {
      get () { return this.data_dense },
      set (v) {
        this.data_dense = v === true
      }
    },
    _clearable: {
      get () { return this.data_clearable },
      set (v) {
        this.data_clearable = v === true
      }
    },
    _readonly: {
      get () { return this.data_readonly },
      set (v) {
        this.data_readonly = v === true
      }
    },
    _disabled: {
      get () { return this.data_disabled },
      set (v) {
        this.data_disabled = v === true
      }
    },
    groupItemValue (item) {
      return {
        type: item.type,
        id: item.id
      }
    }
  },
  data () {
    return {
      data_dense: false,
      data_disabled: false,
      data_readonly: false,
      data_clearable: false,

      searchInput: null,
      selectedId: 2,

      selected: [{ id: '4bad7c06-d5a7-4d32-91da-6c6e06cb8de4', type: 'columns' }],

      dataName: 'toto',
      dataWorkItemTypeId: null,
      required: [v => this.checkRequired(v) || this.$t('t.IsRequired')],
      f1: false,
      items: [],
      dataGroupItem: null

    }
  },
  methods: {
    addItem1 () {
      this.items.push({
        items: []
      })

      this.$waitFor(() => this.$refs?.items?.length).then(() => {
        this.$refs.items[this.$refs.items.length - 1].focus()
      })
    },
    addItem2 (idx) {
      this.items[idx].items.push({})
    },
    checkRequired (v) {
      const r = !!v && !this.lodash.isEqual(v, {}) && !this.lodash.isEqual(v, { id: null })
      return r
    }
  }
}
</script>
<style lang="stylus" scoped>
.full-width
  width 100%

.header
  position sticky
  top 0

.header
  z-index 9999

.header
  position -webkit-sticky
  position sticky
  top 0
</style>
